import _ from "lodash";

/**
 * All the supported ASR models
 */
const ALL_MODELS = [
  {
    get label() {
      return "Latest Long";
    },

    value: "latest_long"
  },

  {
    get label() {
      return "Latest Short";
    },

    value: "latest_short"
  },

  {
    get label() {
      return "Video";
    },

    value: "video"
  },

  {
    get label() {
      return "Phone call";
    },

    value: "phone_call"
  },

  {
    get label() {
      return "ASR: Command and search";
    },

    value: "command_and_search"
  },

  {
    get label() {
      return "ASR: Default";
    },

    value: "default"
  },

  {
    get label() {
      return "General Base";
    },

    value: "general_base"
  },

  {
    get label() {
      return "General Enhanced";
    },

    value: "general_enhanced"
  },

  {
    get label() {
      return "Phone Call Enhanced";
    },

    value: "phonecall_enhanced"
  }
];

/**
 * List of language codes where the default model is phone_call
 */
const PHONE_CALL_DEFAULT_LANGUAGES = [
  "en-US",
  "en-AU",
  "en-GB",
  "fr-CA",
  "fr-FR",
  "ja-JP",
  "pt-BR",
  "ru-RU",
  "es-ES",
  "es-US"
];

/**
 * All the ASR models that support alternate language detection.
 *
 * @type {string[]}
 */
export const ASR_MODELS_SUPPORTING_ALTERNATE_LANGUAGES = [
  "default",
  "command_and_search"
];

/**
 * All the supported ASR models
 */
export const models = ALL_MODELS;

/**
 * Get model by type/types
 * @param {(string|string[])} types
 */
export function whereTypeIs(types) {
  // Make sure types is of type array
  if (typeof types === "string" || types instanceof String) {
    types = [types];
  }

  return ALL_MODELS.filter(model => types.indexOf(model.value) !== -1);
}

/**
 * Get the supported ASR model for a given language
 * @param {Object[]} asrLanguages List of the supported ASR language (see asr_language table)
 * @param {Number} selectedId The ID of the selected ASR language
 * @return {Array}
 */
export function getAsrModelsFromLanguage(asrLanguages, selectedId, provider) {
  let models;
  if (selectedId) {
    const selectedLanguage = asrLanguages
      .filter(lang => lang.language_id === selectedId)
      .pop();
    models = _.get(selectedLanguage, "supported_models", "[]");
    try {
      return JSON.parse(models === undefined ? "[]" : models);
    } catch (e) {
      return [];
    }
  } else {
    const selectedLanguages = asrLanguages.filter(
      lang => lang.language_provider === provider
    );
    models = selectedLanguages.map(lang => lang.supported_models);
    const result = [];
    models.forEach(item => {
      let parsedItem;
      try {
        parsedItem = JSON.parse(item);
        result.push(...parsedItem);
      } catch (e) {
        //
      }
    });
    return Array.from(new Set(result));
  }
}

/**
 * Get the default ASR model for a given language
 * We may call this method later once we have data to suggest which is the most common one
 * @param {Object[]} asrLanguages List of the supported ASR language (see asr_language table)
 * @param {Number} selectedId The ID of the selected ASR language
 * @return {(String|null)} Return the default model if language provider is google, null otherwise
 */
export function getDefaultAsrModelFromLanguage(asrLanguages, selectedId) {
  if (selectedId === null || selectedId === undefined) {
    return "default";
  }

  const selectedLanguage = asrLanguages
    .filter(lang => lang.language_id === selectedId)
    .pop();

  if (_.isEmpty(selectedLanguage)) {
    return null;
  }

  if (selectedLanguage["language_provider"] === "deepgram") {
    let models = _.get(selectedLanguage, "supported_models", null);
    try {
      return JSON.parse(models === undefined ? "[]" : models)[0];
    } catch (e) {
      return null;
    }
  } else if (selectedLanguage["language_provider"] === "google") {
    return (
      PHONE_CALL_DEFAULT_LANGUAGES
        // When support for multiple language provider is required in the future,
        // chain .filter() to filter by provider and .map() to return the value only in this line
        .indexOf(selectedLanguage["language_code"]) >= 0
        ? "phone_call"
        : "default"
    );
  } else {
    return null;
  }
}
